@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/light";
@import "../../node_modules/owl.carousel/src/scss/owl.carousel";

html {
	background: #0B071A;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 1;
	text-transform: uppercase;

	/*@include desktop-sm-down {
		font-size: 15px;
	}*/

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-white;
}

a {
	color: $color-white;

	@include hover-focus {
		color: $color-white;
	}
}

.btn {
	-webit-appearance: none;
	background: $color-white;
	border: 0;
	border-radius: 0;
	color: $color-black;
	display: block;
	font-size: rem(20);
	font-weight: 700;
	line-height: 1;
	margin: rem(30) auto;
	max-width: rem(350);
	opacity: 1;
	padding: rem(20) rem(10);
	text-align: center;
	text-transform: uppercase;

	@include hover-focus {
		color: $color-black;
		opacity: 0.8;
	}
}

.socials {
	list-style: none;
	margin: 0;
	padding: 0;

	> li {
		display: inline-block;
		font-size: rem(18);
		vertical-align: middle;

		+ li {
			margin-left: rem(10);
		}

		> a {
			opacity: 1;

			@include hover-focus {
				opacity: 0.8;
			}
		}
	}
}

header {
	background: #0B071A url('../img/bg/bg@2x.png') no-repeat center top / cover;
	padding: rem(130) 0 rem(95) 0;
	position: relative;
	text-align: center;

	.logos {
		left: rem(30);
		position: absolute;
		top: rem(30);

		img {
			display: inline-block;
			max-width: rem(45);
			vertical-align: middle;

			+ img {
				margin-left: rem(15);
			}
		}
	}

	.container {

		.covers {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			margin-bottom: rem(30);

			@include phone-down {
				display: block;
			}

			> div {
				flex: 0 0 calc(50% - 15px);

				img {
					margin: 0 auto;
				}

				.btn {
					margin-bottom: 0;
					max-width: 100%;
				}

				@include phone-down {
					+ div {
						margin-top: rem(30);
					}
				}
			}
		}

		.btns {
			margin-bottom: rem(50);

			a {
				background: none;
				border: 2px solid $color-white;
				color: $color-white;
				display: inline-block;
				margin: 0;
				width: rem(200);

				@include hover-focus {
					background: $color-white;
					color: $color-black;
				}

				@include phone-sm-down {
					display: block;
					max-width: 100%;
					width: 100%;
				}

				+ a {
					margin-left: rem(10);

					@include phone-sm-down {
						margin-left: 0;
						margin-top: rem(10);
					}
				}
			}
		}
	}
}

main {
	#tour {
		padding: rem(50) 0;

		img {
			margin: 0 auto rem(60) auto;
			max-width: rem(200);
		}
	}

	#videos {
		.container {
			max-width: 100%;
			padding: 0;

			#video-player {
				margin-bottom: rem(50);
			}

			.owl-stage {
				right: -30px;
			}

			.owl-nav {
				> button {
					font-size: rem(50);
					opacity: 1;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					transition: 200ms opacity linear;

					@include hover-focus {
						opacity: 0.8;
					}

					&.disabled {
						opacity: 0;
					}

					&.owl-prev {
						left: rem(10);
					}

					&.owl-next {
						right: rem(10);
					}
				}
			}

			.item {
				a {
					display: block;
					opacity: 1;
					position: relative;

					@include hover-focus {
						opacity: 0.8;
					}

					> span {
						bottom: rem(15);
						display: block;
						foont-size: rem(12);
						left: 0;
						position: absolute;
						right: 0;
						text-align: center;
						z-index: 2;
					}
				}
			}
		}
	}

	#socials {
		padding: rem(95) 0;
		text-align: center;

		.logos {
			margin-bottom: rem(50);

			img {
				display: inline-block;
				max-width: rem(100);
				vertical-align: middle;

				+ img {
					margin-left: rem(30);
				}
			}
		}

		.newsletter {
			margin: 0 auto rem(75) auto;
			max-width: rem(450);

			> span {
				display: block;
				font-size: rem(20);
				margin-bottom: rem(20);
			}

			form {
				.thanks {
					text-align: center;
				}

				input {
					-webkit-appearance: none;
					background: none;
					border: 2px solid $color-white;
					border-radius: 0;
					color: $color-white;
					display: block;
					font-weight: 700;
					padding: rem(10);
					text-transform: uppercase;
					width: 100%;

					@include placeholder {
						color: $color-white;
					}

					+ input {
						margin-top: rem(20);
					}
				}

				button {
					display: block;
					margin: rem(30) auto 0 auto;
					min-width: rem(300);
				}
			}
		}


	}
}

footer {
	padding: rem(25) 0;

	.container {
		max-width: 100%;
	}

	.copyright {
		align-items: center;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		font-size: rem(10);
		justify-content: space-between;

		@include tablet-down {
			display: block;
			line-height: 1.5;
			text-align: center;
		}

		> p {
			margin: 0;

			a {
				@include hover-focus {
					text-decoration: underline;
				}
			}
		}
	}
}
